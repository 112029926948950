:root {
  --bg: 242, 247, 245;
  --head-line: 255, 255, 255;
  --black: #2b2e3a;
  --white: #fff;
  --green: #51ad5a;
  --purple: #7f5af0;
  --gray-bg: #f0f1fa;
  --dark-gray-bg: #e4e6ef;
  --title-color: var(--black);
  --text-color: #94a1b2;
  --menu-link-color: var(--black);
  --panel-color: #fff;
  --theme-1: #d400c8;
  --theme-2: #ff8906;
  --theme-3: #3da9fc;
  --current-theme: var(--theme-1);
  --lvl1: #2cb67d;
  --lvl2: #ffd803;
  --lvl3: #e53170;
  --fb-color: #3b5998;
  --tw-color: #1da1f2;
  --li-color: #0077b5;
  --pt-color: #cb2027;
}

*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}

body {
  background: var(--gray-bg);
  color: var(--text-color);
  letter-spacing: 1px;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

body.dark-theme {
  background: var(--black);
}

::selection {
  background: var(--theme-1);
  color: var(--white);
}

.theme-2 ::selection {
  background: var(--theme-2);
}

.theme-3 ::selection {
  background: var(--theme-3);
}

header {
  position: fixed;
  top: 40px;
  right: 40px;
}

main {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
  min-height: 100vh;
  padding: 5rem;
  display: flex;
}

ul {
  gap: 1rem;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

li {
  align-items: center;
  display: flex;
}

a {
  color: var(--current-theme);
  text-decoration: none;
  transition: all .3s ease-in-out;
}

.theme-2 a {
  color: var(--theme-2);
}

.theme-3 a {
  color: var(--theme-3);
}

a:hover {
  color: var(--black);
}

.dark-theme a:hover {
  color: var(--white);
}

.theme-picker {
  background: var(--white);
  border-radius: 16px;
  gap: 8px;
  padding: 10px 12px;
  box-shadow: 0 0 40px #0000001a;
}

.dark-theme .theme-picker {
  background: var(--dark-gray-bg);
  box-shadow: 0 0 40px #ffffff1a;
}

.intro {
  color: var(--title-color);
  font-size: 5rem;
  line-height: 5.5rem;
  transition: all .3s ease-in-out;
}

.dark-theme .intro {
  color: var(--gray-bg);
}

.intro a {
  color: var(--green);
  font-weight: 700;
}

.logo {
  color: var(--current-theme);
  width: 80px;
}

.logo path {
  fill: #0000;
  stroke: var(--current-theme);
  stroke-width: 2px;
  stroke-dasharray: 200;
  stroke-dashoffset: 0;
  transition: all .6s ease-in-out;
}

.logo:hover path {
  stroke-dashoffset: 400px;
}

.theme-2 .logo path {
  stroke: var(--theme-2);
}

.theme-3 .logo path {
  stroke: var(--theme-3);
}

.soon {
  background: var(--theme-1);
  color: var(--white);
  white-space: nowrap;
  border-radius: 12px;
  width: 300px;
  height: 60px;
  padding: 1rem;
  transition: all .3s ease-in-out;
  position: relative;
  overflow: hidden;
}

.soon p {
  white-space: nowrap;
  animation: 15s linear infinite marquee;
  display: inline-block;
}

.soon p span {
  background: var(--white);
  width: 35px;
  height: 1px;
  margin: 0 .5rem;
  display: inline-block;
  transform: translateY(-6px);
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-650px);
  }
}

.theme-2 .soon {
  background: var(--theme-2);
}

.theme-3 .soon {
  background: var(--theme-3);
}

.theme-picker a {
  border-radius: 12px;
  width: 30px;
  height: 30px;
  display: inline-block;
  position: relative;
}

.theme-picker a[data-theme="1"] {
  background: var(--theme-1);
}

.theme-picker a[data-theme="2"] {
  background: var(--theme-2);
}

.theme-picker a[data-theme="3"] {
  background: var(--theme-3);
}

.theme-picker a.active:before {
  content: "";
  background: var(--white);
  border-radius: 6px;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.theme-toggle {
  background: var(--white);
  color: var(--black);
  outline: 1px solid var(--dark-gray-bg);
}

.theme-toggle.dark {
  background: var(--black);
  color: var(--white);
  outline: none;
}

.theme-toggle:before {
  content: "";
  background: var(--black);
  border-radius: 6px;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.theme-toggle.dark:before {
  background: var(--white);
}

canvas {
  z-index: -1;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

@media screen and (width <= 992px) {
  header {
    top: 35px;
    right: 20px;
  }

  .logo {
    width: 50px;
  }

  main {
    padding: 2rem;
  }

  .intro {
    font-size: 3rem;
    line-height: 3.5rem;
  }

  ul {
    flex-wrap: wrap;
  }
}

@media screen and (width <= 540px) {
  .soon {
    width: 100%;
  }
}

@media screen and (width <= 480px) {
  .intro {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
}

/*# sourceMappingURL=index.29775209.css.map */
