:root {
    --bg: 242, 247, 245;
    --head-line: 255, 255, 255;
    --black: #2b2e3a;
    
    --white: #fff;
    --green: #51AD5A;

    --purple: #7f5af0;
    --gray-bg: #f0f1fa;
    --dark-gray-bg: #e4e6ef;

    --title-color: var(--black);
    --text-color: #94A1B2;
    --menu-link-color: var(--black);
    --panel-color: #fff;

    /* --theme-1: #7F5AF0; */
    --theme-1: #d400c8;
    --theme-2: #ff8906;
    --theme-3: #3da9fc;

    --current-theme: var(--theme-1);

    --lvl1: #2cb67d;
    --lvl2: #ffd803;
    --lvl3: #e53170;

    --fb-color: #3b5998;
    --tw-color: #1da1f2;
    --li-color: #0077b5;
    --pt-color: #cb2027;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    margin: 0;
}

body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
}

img,
picture,
video,
canvas,
svg {
    display: block;
    max-width: 100%;
}

input,
button,
textarea,
select {
    font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    overflow-wrap: break-word;
}

#root,
#__next {
    isolation: isolate;
}

body {
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 16px;
    background: var(--gray-bg);
    color: var(--text-color);
    letter-spacing: 1px;
}

body.dark-theme {
    background: var(--black)
}

::selection {
    background: var(--theme-1);
    color: var(--white);
}

.theme-2 ::selection {
    background: var(--theme-2);
}

.theme-3 ::selection {
    background: var(--theme-3);
}

header {
    position: fixed;
    right: 40px;
    top: 40px;
}

main {
    padding: 5rem;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    align-items: flex-start;
    justify-content: center;
    gap: 2rem;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 1rem;
}

li {
    display: flex;
    align-items: center;
}

a {
    text-decoration: none;
    color: var(--current-theme);
    transition: all 0.3s ease-in-out;
}

.theme-2 a {
    color: var(--theme-2);
}

.theme-3 a {
    color: var(--theme-3);
}

a:hover {
    color: var(--black);
}

.dark-theme a:hover {
    color: var(--white);
}

.theme-picker {
    padding: 10px 12px;
    background: var(--white);
    border-radius: 16px;
    box-shadow: 0 0 40px rgba(0,0,0,0.1);
    gap: 8px;
}

.dark-theme .theme-picker {
    background: var(--dark-gray-bg);
    box-shadow: 0 0 40px rgba(255,255,255,0.1);
}

.intro {
    font-size: 5rem;
    line-height: 5.5rem;
    color: var(--title-color);
    transition: all 0.3s ease-in-out;
}

.dark-theme .intro {
    color: var(--gray-bg);
}

.intro a {
    color: var(--green);
    font-weight: 700;
}

.logo {
    color: var(--current-theme);
    width: 80px;
}

.logo path {
    fill: transparent;
    stroke: var(--current-theme);
    stroke-width: 2px;
    stroke-dasharray: 200;
    stroke-dashoffset: 0;
    transition: all 0.6s ease-in-out;
}

.logo:hover path {
    stroke-dashoffset: 400;
}

.theme-2 .logo path {
    stroke: var(--theme-2);
}

.theme-3 .logo path {
    stroke: var(--theme-3);
}

.soon {
    background: var(--theme-1);
    color: var(--white);
    padding: 1rem;
    border-radius: 12px;
    transition: all 0.3s ease-in-out;
    width: 300px;
    height: 60px;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
}

.soon p {
    display: inline-block;
    white-space: nowrap;
    animation: marquee 15s linear infinite;
}

.soon p span {
    display: inline-block;
    background: var(--white);
    width: 35px;
    height: 1px;
    margin: 0 0.5rem;
    transform: translateY(-6px);
}

@keyframes marquee {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-650px);
    }
}

.theme-2 .soon {
    background: var(--theme-2);
}

.theme-3 .soon {
    background: var(--theme-3);
}

.theme-picker a {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 12px;
    position: relative;
    /* outline: 1px solid var(--black); */
}

.theme-picker a[data-theme='1'] {
    background: var(--theme-1);
}

.theme-picker a[data-theme='2'] {
    background: var(--theme-2);
}

.theme-picker a[data-theme='3'] {
    background: var(--theme-3);
}

.theme-picker a.active::before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 6px;
    background: var(--white);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.theme-toggle {
    background: var(--white);
    color: var(--black);
    outline: 1px solid var(--dark-gray-bg);
}

.theme-toggle.dark {
    background: var(--black);
    color: var(--white); 
    outline: none;
}

.theme-toggle::before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 6px;
    background: var(--black);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.theme-toggle.dark::before {
    background: var(--white);
}

canvas {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

@media screen and (max-width:992px) {

    header {
        right: 20px;
        top: 35px;
    }

    .logo {
        width: 50px;
    }

    main {
        padding: 2rem;
    }

    .intro {
        font-size: 3rem;
        line-height: 3.5rem;
    }

    ul {
        flex-wrap: wrap;
    }

}

@media screen and (max-width:540px) {
    
    .soon {
        width: 100%;
    }

}

@media screen and (max-width:480px) {
    
    .intro {
        font-size: 1.8rem;
        line-height: 2.2rem;
    }

}